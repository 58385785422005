import React, { useContext, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Table from "../../components/Table/Table";
import AuthContext from "../../store/auth-context";
import useRequest from "../../hooks/use-request";
import Loading from "../../components/Loading/Loading";

const Discount = () => {
  const [createUser, setCreateUser] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [confirmedValue, setConfirmedValue] = useState("tashkent");
  const [discounts, setDiscounts] = useState([]);
  const [zone, setZone] = useState("tashkent");

  const [onSuccessMsg, setOnSuccessMsg] = useState(null);
  const authCtx = useContext(AuthContext);

  const discountData = new FormData();

  const handleChangeSorting = (event) => {
    setConfirmedValue(event.target.value);
  };

  const handleChangeZone = (event) => {
    setZone(event.target.value);
  };

  useEffect(() => {
    getDiscounts.doRequest();
  }, [confirmedValue]);
  const getDiscounts = useRequest({
    url2: `https://feed.geogo.io/api/v1/discount/${confirmedValue}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    onSuccess: (data) => {
      setDiscounts(data.data);
    },
  });

  const addDiscount = useRequest({
    url2: `https://feed.geogo.io/api/v1/discount`,
    method: "post",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    body: discountData,
    onSuccess: (data) => {
      getDiscounts.doRequest();
      setCreateUser(false);
      setOnSuccessMsg("Aksiya yaratildi yaratildi.");
    },
  });

  const deleteDiscountRequest = useRequest({
    url2: `https://feed.geogo.io/api/v1/discount`,
    method: "delete",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    body: discountData,
    onSuccess: (data) => {
      getDiscounts.doRequest();
      setCreateUser(false);
      setOnSuccessMsg("Aksiya o'chirildi.");
    },
  });

  const deleteDiscount = async (id) => {
    if (window.confirm("Are you sure?")) {
      await deleteDiscountRequest.doRequest(id);
      setOnSuccessMsg(null);
    }
  };

  const onSubmitDiscountData = async (e) => {
    e.preventDefault();
    discountData.append("title", title);
    discountData.append("description", description);
    discountData.append("zone", zone);
    discountData.append("image", image);

    await addDiscount.doRequest();
  };

  return (
    <React.Fragment>
      {getDiscounts.loading ? <Loading /> : null}
      {addDiscount.loading ? <Loading /> : null}
      {deleteDiscount.loading ? <Loading /> : null}
      <div className="profile-container">
        <div className="right-section">
          <div className="right-section-header">
            <div
              className={
                createUser
                  ? "right-section-control"
                  : "right-section-control active"
              }
            >
              <button
                onClick={() => {
                  setCreateUser(false);
                  setUpdateUser(false);
                  //   getUser.doRequest();
                }}
              >
                Discounts List
              </button>
            </div>
            <div
              className={
                !createUser
                  ? "right-section-control"
                  : "right-section-control active"
              }
            >
              <button onClick={() => setCreateUser(true)}>
                {"Add Discount"}
              </button>
            </div>
          </div>
          {!createUser ? (
            <>
              <div className="sorting-container" style={{ marginTop: "20px" }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={confirmedValue}
                    onChange={handleChangeSorting}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={"tashkent"}>Tashkent</MenuItem>
                    <MenuItem value={"geogievsk"}>Geogievsk</MenuItem>
                    <MenuItem value={"gelendjik"}>Gelendjik</MenuItem>
                    <MenuItem value={"yangiyul"}>Yangiyul</MenuItem>
                    <MenuItem value={"navoiy"}>Navoiy</MenuItem>
                    <MenuItem value={"jizzax"}>Jizzax</MenuItem>
                    <MenuItem value={"nukus"}>Nukus</MenuItem>
                    <MenuItem value={"urgench"}>Urgench</MenuItem>
                    <MenuItem value={"buxoro"}>Buxoro</MenuItem>
                    <MenuItem value={"samarqand"}>Samarqand</MenuItem>
                    <MenuItem value={"guliston"}>Guliston</MenuItem>
                    <MenuItem value={"qarshi"}>Qarshi</MenuItem>
                    <MenuItem value={"namangan"}>Namangan</MenuItem>
                    <MenuItem value={"denov"}>Denov</MenuItem>
                    <MenuItem value={"korea"}>Korea</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Table headers={["title", "description", "image", "Delete"]}>
                {discounts.map((n) => (
                  <TableRow
                    key={n.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {n.title}
                    </TableCell>
                    <TableCell align="left">{n.description}</TableCell>
                    <TableCell align="left">
                      <img
                        crossorigin="anonymous"
                        src={`https://feed.geogo.io/${n.image}`}
                        style={{ width: "100px" }}
                      />
                    </TableCell>
                    <TableCell align="left" style={{ color: "red" }}>
                      <DeleteOutlineOutlinedIcon
                        onClick={() => deleteDiscount(n.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </>
          ) : (
            <div className="right-section-details">
              <form onSubmit={onSubmitDiscountData}>
                <div className="detail">
                  <label>Title</label>
                  <input
                    type={"text"}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required={true}
                  />
                </div>
                <div className="detail">
                  <label>Description</label>
                  <textarea
                    type={"text"}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required={true}
                    style={{ height: "100px" }}
                  ></textarea>
                </div>
                <div className="detail">
                  <label>Image</label>
                  <input
                    type={"file"}
                    // value={}
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>
                <div className="detail">
                  <label>Zone</label>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={zone}
                      onChange={handleChangeZone}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"tashkent"}>Tashkent</MenuItem>
                      <MenuItem value={"geogievsk"}>Geogievsk</MenuItem>
                      <MenuItem value={"gelendjik"}>Gelendjik</MenuItem>
                      <MenuItem value={"yangiyul"}>Yangiyul</MenuItem>
                      <MenuItem value={"navoiy"}>Navoiy</MenuItem>
                      <MenuItem value={"jizzax"}>Jizzax</MenuItem>
                      <MenuItem value={"nukus"}>Nukus</MenuItem>
                      <MenuItem value={"urgench"}>Urgench</MenuItem>
                      <MenuItem value={"buxoro"}>Buxoro</MenuItem>
                      <MenuItem value={"samarqand"}>Samarqand</MenuItem>
                      <MenuItem value={"guliston"}>Guliston</MenuItem>
                      <MenuItem value={"qarshi"}>Qarshi</MenuItem>
                      <MenuItem value={"namangan"}>Namangan</MenuItem>
                      <MenuItem value={"denov"}>Denov</MenuItem>
                      <MenuItem value={"korea"}>Korea</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div style={{ margin: "20px 0" }}>
                  <LoadingButton
                    className="btn"
                    loading={addDiscount.loading}
                    type="submit"
                    variant="contained"
                  >
                    {updateUser ? "Save Changes" : "Create Discount"}
                  </LoadingButton>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Discount;
