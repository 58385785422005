import { NavLink } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";
import GroupIcon from "@mui/icons-material/Group";
import ArticleIcon from "@mui/icons-material/Article";
import AddCommentIcon from "@mui/icons-material/AddComment";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Person2Icon from "@mui/icons-material/Person2";
import DiscountIcon from "@mui/icons-material/Discount";
import { useTranslation } from "react-i18next";
import React from "react";
import "./sidebar.css";

const Sidebar = (props) => {
  const { innerWidth: width } = window;
  const { t } = useTranslation();

  const onCloseSideBar = () => {
    if (width < 768) {
      props.setCloseSidebar();
    }
  };

  return (
    <React.Fragment>
      <div
        className={props.isSidebar ? "sidebar-container-open" : ""}
        onClick={() => props.setOpenCloseSideBar()}
      ></div>
      <div className={props.isSidebar ? "sidebar" : "sidebar closed"}>
        <div className={"sidebar-header"}></div>
        <div className={"sidebar-body"}>
          <ul>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "link active" : "link"
                }
                to={"/"}
                onClick={onCloseSideBar}
              >
                <ChatIcon style={{ paddingRight: "10px" }} />
                Chat
              </NavLink>
            </li>
            {props.role === "admin" && (
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "link active" : "link"
                  }
                  to={"/news"}
                  onClick={onCloseSideBar}
                >
                  <ArticleIcon style={{ paddingRight: "10px" }} />
                  {/* {t("usersList")} */}
                  News
                </NavLink>
              </li>
            )}
            {props.role === "admin" && (
                <li>
                  <NavLink
                      className={({ isActive }) =>
                          isActive ? "link active" : "link"
                      }
                      to={"/extra-news"}
                      onClick={onCloseSideBar}
                  >
                    <ArticleIcon style={{ paddingRight: "10px" }} />
                    {/* {t("usersList")} */}
                   Extra News
                  </NavLink>
                </li>
            )}
            {props.role === "admin" && (
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "link active" : "link"
                  }
                  to={"/discount"}
                  onClick={onCloseSideBar}
                >
                  <DiscountIcon style={{ paddingRight: "10px" }} />
                  {/* {t("usersList")} */}
                  Discounts
                </NavLink>
              </li>
            )}
            {props.role === "admin" && (
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "link active" : "link"
                  }
                  to={"/feedback-status"}
                  onClick={onCloseSideBar}
                >
                  <AddCommentIcon style={{ paddingRight: "10px" }} />
                  {/* {t("usersList")} */}
                  Feedback Status
                </NavLink>
              </li>
            )}

            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "link active" : "link"
                }
                to={"/canseling-reasons"}
                onClick={onCloseSideBar}
              >
                <ListAltIcon style={{ paddingRight: "10px" }} />
                The reasons for canceling the orders
              </NavLink>
            </li>

            {props.role === "admin" && (
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "link active" : "link"
                  }
                  to={"/users"}
                  onClick={onCloseSideBar}
                >
                  <GroupIcon style={{ paddingRight: "10px" }} />
                  {t("usersList")}
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "link active" : "link"
                }
                to={"/profile"}
                onClick={onCloseSideBar}
              >
                <Person2Icon style={{ paddingRight: "10px" }} />
                {t("prof")}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
