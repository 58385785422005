import React, {useContext, useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {
    FormControl,
    MenuItem,
    Select,
    TableCell,
    TableRow,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Table from "../../components/Table/Table";
import AuthContext from "../../store/auth-context";
import useRequest from "../../hooks/use-request";
import Loading from "../../components/Loading/Loading";

const ExtraNews = () => {
    const [createUser, setCreateUser] = useState(false);
    const [updateUser, setUpdateUser] = useState(false);
    const [title_uz, setTitleUz] = useState("");
    const [title_ru, setTitleRu] = useState("");
    const [title_en, setTitleEn] = useState("");
    const [title_kr, setTitleKr] = useState("");
    const [title_gr, setTitleGr] = useState("");
    const [title_krKrill, setTitleKrKrill] = useState("");
    const [title_uzKrill, setTitleUzKrill] = useState("");
    const [confirmedValue, setConfirmedValue] = useState("tashkent");
    const [news, setNews] = useState([]);
    const [zone, setZone] = useState("tashkent");

    const [onSuccessMsg, setOnSuccessMsg] = useState(null);
    const authCtx = useContext(AuthContext);

    const handleChangeSorting = (event) => {
        setConfirmedValue(event.target.value);
    };

    const handleChangeZone = (event) => {
        setZone(event.target.value);
    };

    useEffect(() => {
        getNews.doRequest();
    }, [confirmedValue]);
    const getNews = useRequest({
        url: `/extra-news/${confirmedValue}?lan=all`,
        method: "get",
        headers: {
            Authorization: `Bearer ${authCtx.token}`,
        },
        onSuccess: (data) => {
            setNews(data.data);
        },
    });

    const addNews = useRequest({
        url: `/extra-news`,
        method: "post",
        headers: {
            Authorization: `Bearer ${authCtx.token}`,
        },
        body: {
            title_uz,
            title_ru,
            title_en,
            title_kr,
            title_gr,
            title_krKrill,
            title_uzKrill,
            zone,
        },
        onSuccess: (data) => {
            getNews.doRequest();
            setCreateUser(false);
            setOnSuccessMsg("ExtraNews yaratildi.");
        },
    });

    const deleteNewsRequest = useRequest({
        url: `/extra-news`,
        method: "delete",
        headers: {
            Authorization: `Bearer ${authCtx.token}`,
        },
        body: {},
        onSuccess: (data) => {
            getNews.doRequest();
            setCreateUser(false);
            setOnSuccessMsg("ExtraNews o'chirildi.");
        },
    });

    const deleteNews = async (id) => {
        if (window.confirm("Are you sure?")) {
            await deleteNewsRequest.doRequest(id);
            setOnSuccessMsg(null);
        }
    };

    const onSubmitNewsData = async (e) => {
        e.preventDefault();
        await addNews.doRequest();
    };

    return (
        <React.Fragment>
            {getNews.loading ? <Loading/> : null}
            {addNews.loading ? <Loading/> : null}
            {deleteNews.loading ? <Loading/> : null}
            <div className="profile-container">
                <div className="right-section">
                    <div className="right-section-header">
                        <div
                            className={
                                createUser
                                    ? "right-section-control"
                                    : "right-section-control active"
                            }
                        >
                            <button
                                onClick={() => {
                                    setCreateUser(false);
                                    setUpdateUser(false);
                                    //   getUser.doRequest();
                                }}
                            >
                                News List
                            </button>
                        </div>
                        <div
                            className={
                                !createUser
                                    ? "right-section-control"
                                    : "right-section-control active"
                            }
                        >
                            <button onClick={() => setCreateUser(true)}>{"Add ExtraNews"}</button>
                        </div>
                    </div>
                    {!createUser ? (
                        <>
                            <div className="sorting-container" style={{marginTop: "20px"}}>
                                <FormControl sx={{m: 1, minWidth: 120}}>
                                    <Select
                                        value={confirmedValue}
                                        onChange={handleChangeSorting}
                                        displayEmpty
                                        inputProps={{"aria-label": "Without label"}}
                                    >
                                        <MenuItem value={"tashkent"}>Tashkent</MenuItem>
                                        <MenuItem value={"geogievsk"}>Geogievsk</MenuItem>
                                        <MenuItem value={"gelendjik"}>Gelendjik</MenuItem>
                                        <MenuItem value={"yangiyul"}>Yangiyul</MenuItem>
                                        <MenuItem value={"navoiy"}>Navoiy</MenuItem>
                                        <MenuItem value={"jizzax"}>Jizzax</MenuItem>
                                        <MenuItem value={"nukus"}>Nukus</MenuItem>
                                        <MenuItem value={"urgench"}>Urgench</MenuItem>
                                        <MenuItem value={"buxoro"}>Buxoro</MenuItem>
                                        <MenuItem value={"samarqand"}>Samarqand</MenuItem>
                                        <MenuItem value={"guliston"}>Guliston</MenuItem>
                                        <MenuItem value={"qarshi"}>Qarshi</MenuItem>
                                        <MenuItem value={"namangan"}>Namangan</MenuItem>
                                        <MenuItem value={"denov"}>Denov</MenuItem>
                                        <MenuItem value={"korea"}>Korea</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <Table
                                headers={[
                                    "title Uz",
                                    "title Ru",
                                    "title En",
                                    "title Kr",
                                    "title Gr",
                                    "title KrKrill",
                                    "title UzKrill",
                                    "Delete",
                                ]}
                            >
                                {news.map((n) => (
                                    <TableRow
                                        key={n.id}
                                        sx={{
                                            "&:last-child td, &:last-child th": {
                                                border: 0,
                                            },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {n.title_uz}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {n.title_ru}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {n.title_en}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {n.title_kr}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {n.title_gr}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {n.title_krKrill}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {n.title_uzKrill}
                                        </TableCell>
                                        <TableCell align="left" style={{color: "red"}}>
                                            <DeleteOutlineOutlinedIcon
                                                onClick={() => deleteNews(n.id)}
                                                style={{cursor: "pointer"}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </>
                    ) : (
                        <div className="right-section-details">
                            <form onSubmit={onSubmitNewsData}>
                                <div className="detail">
                                    <label>Title Uz</label>
                                    <input
                                        type={"text"}
                                        value={title_uz}
                                        onChange={(e) => setTitleUz(e.target.value)}
                                        required={true}
                                    />
                                </div>
                                <div className="detail">
                                    <label>Title Ru</label>
                                    <input
                                        type={"text"}
                                        value={title_ru}
                                        onChange={(e) => setTitleRu(e.target.value)}
                                        required={true}
                                    />
                                </div>
                                <div className="detail">
                                    <label>Title En</label>
                                    <input
                                        type={"text"}
                                        value={title_en}
                                        onChange={(e) => setTitleEn(e.target.value)}
                                        required={true}
                                    />
                                </div>
                                <div className="detail">
                                    <label>Title Kr</label>
                                    <input
                                        type={"text"}
                                        value={title_kr}
                                        onChange={(e) => setTitleKr(e.target.value)}
                                        required={true}
                                    />
                                </div>
                                <div className="detail">
                                    <label>Title Gr</label>
                                    <input
                                        type={"text"}
                                        value={title_gr}
                                        onChange={(e) => setTitleGr(e.target.value)}
                                        required={true}
                                    />
                                </div>
                                <div className="detail">
                                    <label>Title UzKrill</label>
                                    <input
                                        type={"text"}
                                        value={title_uzKrill}
                                        onChange={(e) => setTitleUzKrill(e.target.value)}
                                        required={true}
                                    />
                                </div>
                                <div className="detail">
                                    <label>Title KrKrill</label>
                                    <input
                                        type={"text"}
                                        value={title_krKrill}
                                        onChange={(e) => setTitleKrKrill(e.target.value)}
                                        required={true}
                                    />
                                </div>
                                <div className="detail">
                                    <label>Zone</label>
                                    <FormControl sx={{m: 1, minWidth: 120}}>
                                        <Select
                                            value={zone}
                                            onChange={handleChangeZone}
                                            displayEmpty
                                            inputProps={{"aria-label": "Without label"}}
                                        >
                                            <MenuItem value={"tashkent"}>Tashkent</MenuItem>
                                            <MenuItem value={"geogievsk"}>Geogievsk</MenuItem>
                                            <MenuItem value={"gelendjik"}>Gelendjik</MenuItem>
                                            <MenuItem value={"yangiyul"}>Yangiyul</MenuItem>
                                            <MenuItem value={"navoiy"}>Navoiy</MenuItem>
                                            <MenuItem value={"jizzax"}>Jizzax</MenuItem>
                                            <MenuItem value={"nukus"}>Nukus</MenuItem>
                                            <MenuItem value={"urgench"}>Urgench</MenuItem>
                                            <MenuItem value={"buxoro"}>Buxoro</MenuItem>
                                            <MenuItem value={"samarqand"}>Samarqand</MenuItem>
                                            <MenuItem value={"guliston"}>Guliston</MenuItem>
                                            <MenuItem value={"qarshi"}>Qarshi</MenuItem>
                                            <MenuItem value={"namangan"}>Namangan</MenuItem>
                                            <MenuItem value={"denov"}>Denov</MenuItem>
                                            <MenuItem value={"korea"}>Korea</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{margin: "20px 0"}}>
                                    <LoadingButton
                                        className="btn"
                                        loading={addNews.loading}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {updateUser ? "Save Changes" : "Create ExtraNews"}
                                    </LoadingButton>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ExtraNews;
