import React, { useContext, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { TableCell, TableRow } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Table from "../../components/Table/Table";
import AuthContext from "../../store/auth-context";
import useRequest from "../../hooks/use-request";
import Loading from "../../components/Loading/Loading";

const FeedbackStatus = () => {
  const [createUser, setCreateUser] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [feedbackStatus, setFeedbackStatus] = useState([]);
  const [status, setStatus] = useState("");
  const [uz, setUz] = useState("");
  const [ru, setRu] = useState("");
  const [en, setEn] = useState("");
  const [kr, setKr] = useState("");
  const [uzKrill, setUzKrill] = useState("");
  const [krKrill, setKrKrill] = useState("");
  const [gr, setGr] = useState("");
  const [id, setId] = useState();

  const [onSuccessMsg, setOnSuccessMsg] = useState(null);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    getFeedbackStatus.doRequest();
  }, []);
  const getFeedbackStatus = useRequest({
    url2: `https://feed.geogo.io/api/v1/feedback-status?lan=uz`,
    method: "get",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    onSuccess: (data) => {
      setFeedbackStatus(data.data);
    },
  });

  const addFeedbackStatusRequest = useRequest({
    url2: `https://feed.geogo.io/api/v1/feedback-status`,
    method: "post",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    body: {
      status,
      uz,
      ru,
      en,
      kr,
      uzKrill,
      krKrill,
      gr,
    },
    onSuccess: (data) => {
      getFeedbackStatus.doRequest();
      setCreateUser(false);
      setOnSuccessMsg("FeedbackStatus yaratildi.");
      setStatus("");
      setUz("");
      setRu("");
      setEn("");
      setKr("");
      setUzKrill("");
      setKrKrill("");
      setGr("");
    },
  });

  const deleteFeedbackStatusRequest = useRequest({
    url2: `https://feed.geogo.io/api/v1/feedback-status`,
    method: "delete",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    body: {},
    onSuccess: (data) => {
      getFeedbackStatus.doRequest();
      setCreateUser(false);
      setOnSuccessMsg("FeedbackStatus o'chirildi.");
    },
  });

  const updateFeedbackStatusRequest = useRequest({
    url2: `https://feed.geogo.io/api/v1/feedback-status/${id}`,
    method: "put",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    body: {
      status,
      uz,
      ru,
      en,
      kr,
      uzKrill,
      krKrill,
      gr,
    },
    onSuccess: (data) => {
      setOnSuccessMsg("FeedbackStatus ma'lumotlari o'zgartirildi");
      getFeedbackStatus.doRequest();
      setUpdateUser(false);
      setCreateUser(false);
    },
  });

  const updateFeedbackStatusData = (id) => {
    setId(id);
    feedbackStatus.find((feedback) => {
      if (feedback.id === id) {
        setStatus(feedback.status);
        setUz(feedback.uz);
        setRu(feedback.ru);
        setEn(feedback.en);
        setKr(feedback.kr);
        setKrKrill(feedback.krKrill);
        setUzKrill(feedback.uzKrill);
        setGr(feedback.gr);
      }
    });
    setUpdateUser(true);
    setCreateUser(true);
  };

  const deleteFeedbackStatus = async (id) => {
    if (window.confirm("Are you sure?")) {
      await deleteFeedbackStatusRequest.doRequest(id);
      setOnSuccessMsg(null);
    }
  };

  const onSubmitFeedbackStatusData = async (e) => {
    e.preventDefault();
    if (!updateUser) {
      await addFeedbackStatusRequest.doRequest();
      setOnSuccessMsg(null);
    } else {
      await updateFeedbackStatusRequest.doRequest();
    }
  };

  return (
    <React.Fragment>
      {getFeedbackStatus.loading ? <Loading /> : null}
      {addFeedbackStatusRequest.loading ? <Loading /> : null}
      {deleteFeedbackStatusRequest.loading ? <Loading /> : null}
      {updateFeedbackStatusRequest.loading ? <Loading /> : null}
      <div className="profile-container">
        <div className="right-section">
          <div className="right-section-header">
            <div
              className={
                createUser
                  ? "right-section-control"
                  : "right-section-control active"
              }
            >
              <button
                onClick={() => {
                  setCreateUser(false);
                  setUpdateUser(false);
                  //   getUser.doRequest();
                }}
              >
                FeedbackStatus List
              </button>
            </div>
            <div
              className={
                !createUser
                  ? "right-section-control"
                  : "right-section-control active"
              }
            >
              <button onClick={() => setCreateUser(true)}>
                {"Add FeedbackStatus"}
              </button>
            </div>
          </div>
          {!createUser ? (
            <>
              <Table
                headers={[
                  "status",
                  "uz",
                  "ru",
                  "en",
                  "kr",
                  "uzKrill",
                  "krKrill",
                  "gr",
                  "Edit",
                  "Delete",
                ]}
              >
                {feedbackStatus.map((feed) => (
                  <TableRow
                    key={feed.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {feed.status}
                    </TableCell>
                    <TableCell align="left">{feed.uz}</TableCell>
                    <TableCell align="left">{feed.ru}</TableCell>
                    <TableCell align="left">{feed.en}</TableCell>
                    <TableCell align="left">{feed.kr}</TableCell>
                    <TableCell align="left">{feed.uzKrill}</TableCell>
                    <TableCell align="left">{feed.krKrill}</TableCell>
                    <TableCell align="left">{feed.gr}</TableCell>
                    <TableCell
                      align="left"
                      style={{ color: "rgb(62, 147, 251)" }}
                    >
                      <BorderColorOutlinedIcon
                        onClick={() => updateFeedbackStatusData(feed.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </TableCell>
                    <TableCell align="left" style={{ color: "red" }}>
                      <DeleteOutlineOutlinedIcon
                        onClick={() => deleteFeedbackStatus(feed.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </>
          ) : (
            <div className="right-section-details">
              <form onSubmit={onSubmitFeedbackStatusData}>
                <div className="detail">
                  <label>Status</label>
                  <input
                    type={"text"}
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required={true}
                  />
                </div>
                <div className="detail">
                  <label>Uz</label>
                  <input
                    type={"text"}
                    value={uz}
                    onChange={(e) => setUz(e.target.value)}
                    required={true}
                  />
                </div>
                <div className="detail">
                  <label>Ru</label>
                  <input
                    type={"text"}
                    value={ru}
                    onChange={(e) => setRu(e.target.value)}
                  />
                </div>
                <div className="detail">
                  <label>En</label>
                  <input
                    type={"text"}
                    value={en}
                    onChange={(e) => setEn(e.target.value)}
                  />
                </div>
                <div className="detail">
                  <label>Kr</label>
                  <input
                    type={"text"}
                    value={kr}
                    onChange={(e) => setKr(e.target.value)}
                  />
                </div>
                <div className="detail">
                  <label>uzKrill</label>
                  <input
                    type={"text"}
                    value={uzKrill}
                    onChange={(e) => setUzKrill(e.target.value)}
                  />
                </div>
                <div className="detail">
                  <label>KrKrill</label>
                  <input
                    type={"text"}
                    value={krKrill}
                    onChange={(e) => setKrKrill(e.target.value)}
                  />
                </div>
                <div className="detail">
                  <label>Gr</label>
                  <input
                    type={"text"}
                    value={gr}
                    onChange={(e) => setGr(e.target.value)}
                  />
                </div>
                <div style={{ margin: "20px 0" }}>
                  <LoadingButton
                    className="btn"
                    loading={addFeedbackStatusRequest.loading}
                    type="submit"
                    variant="contained"
                  >
                    {updateUser ? "Save Changes" : "Create FeedbackStatus"}
                  </LoadingButton>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeedbackStatus;
