import React, { useContext, useEffect, useState } from "react";

import Table from "../../components/Table/Table";
import useRequest from "../../hooks/use-request";
import AuthContext from "../../store/auth-context";
import { TableCell, TableRow } from "@mui/material";
import Pagination from "../../components/Pagination/Pagination";
import Loading from "../../components/Loading/Loading";

const CancelingReasons = () => {
  const [reasons, setReasons] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [allPages, setAllPages] = useState(0);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    getCancelingReasons.doRequest();
  }, [currentPage]);

  const getCancelingReasons = useRequest({
    url: `/complains?type=toOrder&size=20&page=${currentPage}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    onSuccess: (data) => {
      setCurrentPage(data.data.pagination.page);
      setCount(data.data.pagination.allItemsCount);
      setAllPages(data.data.pagination.allPagesCount);
      setReasons(data.data.content);
    },
  });

  const changeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <React.Fragment>
      {getCancelingReasons.loading && <Loading />}
      <div className="profile-container">
        <div className="right-section">
          <>
            <Table
              headers={[
                "Id",
                "message",
                "orderId",
                "complainent",
                "toUser",
                "createdAt",
              ]}
            >
              {reasons.map((reason) => (
                <TableRow
                  key={reason.id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {reason.id}
                  </TableCell>
                  <TableCell align="left">{reason.message}</TableCell>
                  <TableCell align="left">{reason.orderId}</TableCell>
                  <TableCell align="left">{reason.complainent}</TableCell>
                  <TableCell align="left">{reason.toUser}</TableCell>
                  <TableCell align="left">{reason.createdAt}</TableCell>
                </TableRow>
              ))}
            </Table>
            <div className="pagination">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={count}
                pageSize={allPages}
                onPageChange={(page) => changeCurrentPage(page)}
              />
            </div>
          </>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CancelingReasons;
