import React, { useState, useEffect, useContext } from "react";
import "./chat.css";
import useRequest from "../../hooks/use-request";
import AuthContext from "../../store/auth-context";
import Loading from "../../components/Loading/Loading";
import { FormControl, MenuItem, Select } from "@mui/material";

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [adminEmail, setAdminEmail] = useState(null);
  const [isChat, setIsChat] = useState(false);
  const [activeChat, setActiveChat] = useState(null);
  const [userId, setUserId] = useState(null);
  const [confirmedValue, setConfirmedValue] = useState("out-archive");

  const authCtx = useContext(AuthContext);

  const handleChangeSorting = (event) => {
    setConfirmedValue(event.target.value);
    setIsChat(false);
    setMessages([]);
    setActiveChat(null);
  };

  const getLoggedInUser = useRequest({
    url: `/auth/user`,
    method: "get",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    onSuccess: (data) => {
      setAdminEmail(data.data.email);
    },
  });

  const fetchChats = useRequest({
    url: `/support-chat/${confirmedValue}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    onSuccess: (data) => {
      if (data.data) {
        setChats(data.data);
      } else {
        setChats([]);
      }
    },
  });
  // cons
  const fetchMessagesRequest = useRequest({
    url: `/support-chat`,
    method: "get",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    onSuccess: (data) => {
      setMessages(data.data.supportMessage);
      setUserId(data.data.firstUser);
      setIsChat(true);
      data.data.supportMessage.map((message) => {
        if (
          (message.toUser === null || message.toUser === adminEmail) &&
          message.isRead === false
        ) {
          // setMessageId(message.id);
          readMessages.doRequest(message.id);
        }
      });
    },
  });

  const fetchMessages = async (id) => {
    setMessages([]);
    await fetchMessagesRequest.doRequest(id);
  };

  const readMessages = useRequest({
    url: `/support-message`,
    method: "put",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    onSuccess: (data) => {
      console.log("READ_MESSAGE", data);
    },
  });

  // Simulate fetching chat messages from an API
  useEffect(() => {
    getLoggedInUser.doRequest();
    fetchChats.doRequest();
  }, [confirmedValue]);

  const handleInputChange = (event) => {
    setNewMessage(event.target.value);
  };

  const sendMessageRequest = useRequest({
    url: `/support-message`,
    method: "post",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    body: {
      orderId: activeChat,
      fromUser: adminEmail,
      toUser: userId,
      message: newMessage,
    },
    onSuccess: (data) => {
      setMessages([...messages, data.data]);
      setNewMessage("");
    },
  });

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      sendMessageRequest.doRequest();
    }
  };

  return (
    <React.Fragment>
      {fetchChats.loading && <Loading />}
      {getLoggedInUser.loading && <Loading />}
      {fetchMessagesRequest.loading && <Loading />}
      <div className="profile-container">
        <div className="right-section complain-order-history-container">
          <div className="orders-container">
            <ul>
              {chats.map((chat) => (
                <li
                  key={chat.id}
                  onClick={() => {
                    fetchMessages(chat.orderId);
                    setActiveChat(chat.orderId);
                  }}
                  className={activeChat === chat.orderId ? "active" : null}
                >
                  {chat.orderId}
                </li>
              ))}
            </ul>
            <div className="sorting-container">
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={confirmedValue}
                  onChange={handleChangeSorting}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={"out-archive"}>Unread messages</MenuItem>
                  <MenuItem value="in-archive">Readed messages</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="chat-container">
            <div className="chat-messages">
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`message-container ${
                    message.fromUser === adminEmail ? "user" : "other"
                  }`}
                >
                  <div
                    key={message.id}
                    className={`message ${
                      message.fromUser === adminEmail ? "user" : "other"
                    }`}
                  >
                    {message.message}
                  </div>
                </div>
              ))}
            </div>
            {isChat && (
              <div className="chat-input">
                <input
                  type="text"
                  value={newMessage}
                  onChange={handleInputChange}
                  placeholder="Type your message..."
                />
                <button onClick={handleSendMessage}>Send</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Chat;
